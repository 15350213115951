/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ol: "ol",
    li: "li",
    h2: "h2",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Creating a form"), "\n", React.createElement(_components.p, null, "So let's say you want to create a form, if you read the first part you know you will need two things:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Create a form here"), "\n", React.createElement(_components.li, null, "Add an HTML form to your page, with the forwd.io URL we gave you in first step."), "\n"), "\n", React.createElement(_components.h2, null, "Create a form on Forwarder.cc"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Click on ", React.createElement(_components.strong, null, "\"+ Add a new form\""), " in the sidebar:\n", React.createElement(_components.img, {
    src: "add-new-form.png",
    alt: ""
  })), "\n", React.createElement(_components.li, null, "Fill the modal\n", React.createElement(_components.img, {
    src: "new-form.png",
    alt: ""
  })), "\n", React.createElement(_components.li, null, "Save it"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
